import { Theme } from 'theme-ui';

export const theme: Theme = {
  colors: {
    text: '#000',
    background: '#F2E6D0',
    primary: '#4F6573',
    secondary: '#809FA6',
    accent: '#A67C90',
    highlight: '#D9ABA0',
  },
  fonts: {
    heading: 'Montserrat',
  },
  text: {
    default: {
      fontFamily: 'Lato',
    },
  },
};
