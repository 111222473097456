import React from 'react';
import { Section, SectionMain, SectionAside } from './components/section';
import { Text, Heading, Grid, Label, Input, Box, Button } from 'theme-ui';

export const Story = () => (
  <Box sx={{ bg: 'highlight', my: 2 }}>
    <Section
      sx={{
        bg: 'highlight',
        height: '100%',
        maxWidth: '600px',
        margin: 'auto',
        maxHeight: 'auto',
        gridTemplateColumns: '1fr',
        gridTemplateAreas: undefined,
      }}
    >
      <SectionAside sx={{ flexDirection: 'column', gridArea: undefined }}>
        <Grid sx={{ gap: 4 }}>
          <Heading>Join the Hive</Heading>

          <Text sx={{ lineHeight: 1.25 }}>
            This story starts in the Heart of Texas. Somewhere along a rocky
            hill filled with wildflowers. The buzzards congregate and chatter on
            the telephone poles. The bees are working hard below, transporting
            the nectar from the wildflowers and turning it into honey for the
            coming winter.
          </Text>
          <Text sx={{ lineHeight: 1.25 }}>
            Buzzard Honey is about embracing the important things in life -
            Love, Family, Nature, and Curiousity.
          </Text>
          <Text sx={{ lineHeight: 1.25 }}>
            While Buzzard Honey is not for sale, Jim and Kay have a good amount
            to share every year. Join the newsletter for information on the
            annual honey harvest!
          </Text>
          {/* <Text>
          There was a beautiful Hueco princess who suffered a terrible disease.
          A disease so strange that no doctors of her father, king Strong Wind,
          had been able to cure.
        </Text>
        <Text>
          Desperate, the King turned to the older priests in the tribe to find a
          cure for his beautiful daughter. They told him that according to the
          timeless stories of travelers, in a far away northern land, there were
          strange and hidden hives. Honey in which one only needed to consume a
          small morsel to regain vigor, joy, strength, and refreshment. A simple
          rhyme held the key:
        </Text>
        <Grid sx={{ py: 3, color: 'background', gap: 2 }}>
          <Text>Where two rivers become one</Text>
          <Text>Follow the setting sun</Text>
          <Text>Look for buzzards nightly rest</Text>
          <Text>Up top a rocky crest</Text>
        </Grid>
        <Text>
          Encouraged by this news, Strong Wind ordered an immediate expedition
          to take his daughter to this amazing place. Hours were spent traveling
          up the river, but the Princess and her expedition finally reached the
          rocky bluff. True to the legend, a large flock of buzzards were
          quietly resting for the evening. After spending some time there,
          foraging for honey and drinking from the springs, the daughter of
          Strong Wind, his priests, and chiefs returned to the Hueco lands
          optimistic, strong, happy, and refreshed. The news about the
          Princess’s recovery spread throughout the kingdom and has passed from
          generation to generation up until modern times.
        </Text> */}
        </Grid>
      </SectionAside>
      <SectionMain
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gridArea: undefined,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Label>
            <Text>Email Address</Text>
          </Label>
          <Input mt={1} />
          <Button sx={{ my: 3, bg: 'accent' }}>Sign Up</Button>
        </Box>
      </SectionMain>
    </Section>
  </Box>
);
