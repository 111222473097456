import React from 'react';

import { Grid } from 'theme-ui';
import { Picture } from './components/picture';

export interface PictureGridProps {
  images: any[];
}

export const PictureGrid = ({ images }: PictureGridProps) => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridAutoRows: 'minmax(200px, 50vh)',
        p: 2,
        gap: 2,
        bg: 'neutral',
      }}
    >
      {images.map((img, i) => (
        <Picture src={img} key={i} />
      ))}
    </Grid>
  );
};
