import React, { useState } from 'react';
import { Section, SectionMain, SectionAside } from './components/section';
import { Text, Heading, Grid } from 'theme-ui';
import { StaticMap } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export const Newsletter = () => (
  <Section>
    <SectionMain sx={{ bg: 'primary' }}>
      <Map />
    </SectionMain>
    <SectionAside sx={{ bg: 'secondary', flexDirection: 'column' }}>
      <Grid sx={{ gap: 2 }}>
        <Heading>Join the Hive</Heading>
        <Text>
          While Buzzard Honey is not for sale, Jim and Kay have a good amount to
          share every year. Join the newsletter for information on the annual
          honey harvest!
        </Text>
      </Grid>
    </SectionAside>
  </Section>
);

export const Map = () => {
  const [viewport, setViewport] = useState({
    latitude: 31.50594,
    longitude: -97.32889,
    zoom: 12,
    bearing: 0,
    pitch: 0,
  });

  return (
    <StaticMap
      {...viewport}
      width="100%"
      height="100%"
      mapStyle="mapbox://styles/patrickmoore053/ckj2gmztg3hw019qybwf4wz5z"
      mapboxApiAccessToken={
        'pk.eyJ1IjoicGF0cmlja21vb3JlMDUzIiwiYSI6ImNrajJiZmZlMDUxajQyc3BkbzBqdzNiaTkifQ.tEm_mT30oODNaltI-hDiDA'
      }
    />
  );
};
