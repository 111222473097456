import React from 'react';
import { Heading, Flex, Image } from 'theme-ui';
import Bee from './bee.svg';

export const Home = () => (
  <Flex
    sx={{
      height: '100vh',
      width: '100vw',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      bg: 'accent',
    }}
  >
    <Heading
      sx={{
        fontSize: [5, 6, 8],
        letterSpacing: '0.1em',
        wordSpacing: '30px',
        pb: 4,
        color: 'bg',
      }}
    >
      Buzzard
    </Heading>
    <Image sx={{ maxWidth: '100%' }} src={Bee} alt="Logo" />
    <Heading
      sx={{
        fontSize: 4,
        letterSpacing: '0.2em',
        wordSpacing: '30px',
        color: 'bg',
      }}
    >
      Honey
    </Heading>
  </Flex>
);
