import React from 'react';
import { Home } from './home';
import { PictureGrid } from './grid';
import { Story } from './story';
import { Map } from './newsletter';
import Boys from './boys.jpeg';
import Parents from './parents.jpeg';
import River from './river.jpeg';
import MommyMom from './mommymom.jpeg';
import Sunset from './sunset.jpeg';
import Wildflowers from './wildflowers.jpeg';
import { Flex } from 'theme-ui';

export const App = () => (
  <div>
    <Home />
    <Story />
    <Flex sx={{ height: '100vh', width: '100vw', bg: 'secondary' }}>
      <Map />
    </Flex>
    <PictureGrid
      images={[Wildflowers, Parents, Boys, River, MommyMom, Sunset]}
    />
  </div>
);
