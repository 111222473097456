import React, { FC } from 'react';
import { Grid, Flex, SxProps } from 'theme-ui';

export const Section: FC<{ sx?: SxProps }> = ({ children, sx }) => {
  return (
    <Grid
      sx={{
        height: '100vh',
        width: '100vw',
        gridTemplateColumns: '30% 1fr',
        gridTemplateAreas: '"aside main"',
        gap: 0,
        overflow: 'hidden',
        maxHeight: '100vh',
        ...sx,
      }}
    >
      {children}
    </Grid>
  );
};

export const SectionAside: FC<{ sx?: SxProps }> = ({ children, sx }) => {
  return <Flex sx={{ gridArea: 'aside', p: 4, ...sx }}>{children}</Flex>;
};

export const SectionMain: FC<{ sx?: SxProps }> = ({ children, sx }) => {
  return <Flex sx={{ gridArea: 'main', p: 4, ...sx }}>{children}</Flex>;
};
