import React from 'react';
import { Image, Flex, SxStyleProp } from 'theme-ui';

export interface PictureProps {
  src: any;
  sx?: SxStyleProp;
}

export const Picture = ({ src, sx }: PictureProps) => {
  return (
    <Flex>
      <Image sx={{ objectFit: 'cover' }} src={src} />
    </Flex>
  );
};
